import React from 'react'
// import Layout from '../components/layout';
import { MainPage } from '../templates/Test/mainPage';
// import { TestingPage } from '../templates/Test/testingPage'

const test = () => {
  return (
    <div>
      {/* <Layout> */}
      {/* <TestingPage /> */}
      <MainPage />
      {/* </Layout> */}
    </div>
  )
}

export default test;