import React, { useRef } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import AboutCIC from "../Home/aboutCIC";

export const ExampleContent = () => (
  // <div className="example-content-container">
  //   <h2 className="content-heading">Additional content explaining the above card here</h2>
  //   <div className="content-body">
  //     <p className="content-paragraph">
  //       Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
  //       blanditiis soluta eius quam modi aliquam quaerat odit deleniti minima
  //       maiores voluptate est ut saepe accusantium maxime doloremque nulla
  //       consectetur possimus.
  //     </p>
  //     <p className="content-paragraph">
  //       Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
  //       reiciendis blanditiis aliquam aut fugit sint.
  //     </p>
  //     <button className="learn-more-btn">
  //       Learn more <FiArrowUpRight className="icon" />
  //     </button>
  //   </div>
  // </div>
  <AboutCIC/>
);
